import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GiNewspaper as Newspaper } from "react-icons/gi"

export default function CoverImage({ src: cover_image }) {
  const data = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            resize(height: 75, width: 75) {
              src
              tracedSVG
              width
              height
              aspectRatio
              originalName
            }
          }
        }
      }
    }
  `)

  const images = data.allImageSharp.edges
  const locateImage = img => {
    if (!img) {
      return null
    }
    const node = images.find(
      ({ node }) => node.resize.originalName === img.base
    )
    return node ? node.node.resize.src : null
  }
  let imageSrc

  return (
    <div>
      <div>
        {!!(imageSrc = locateImage(cover_image)) ? (
          <img alt="devsurvival blog post" src={imageSrc} />
        ) : (
          <Newspaper style={{ width: 75, height: 75 }} />
        )}
      </div>
    </div>
  )
}
