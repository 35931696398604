import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"
import CoverImage from "../components/coverImage"
import { FaTag } from "react-icons/fa"
//import DevToApi from "../helpers/devto.api.js"

const BlogIndex = ({ data, location }) => {
  const { title: siteTitle, popularTopics } = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges
  const [selection, setSelection] = useState([])
  const [popularTopicState, setPopularTopicState] = useState(
    popularTopics.map(value => ({ value, active: false }))
  )
  const numberOfPosts = 12
  const filteredPosts = posts
    .filter(({ node }) => {
      //checking if the topic selection matches the tags of the posts
      const topicSelectContainsTag =
        (!selection ||
          selection.length === 0 ||
          selection.some(s => node.frontmatter.tags.includes(s.value))) &&
        popularTopicState.every(t => !t.active) // making sure that there is no selected popular tags

      // checking if the posts tags matchines the selected popular tags
      const popularTopicSelectIncludesTag = popularTopicState.some(t => {
        return t.active && node.frontmatter.tags.includes(t.value)
      })

      // if either one are true, then display the post
      return topicSelectContainsTag || popularTopicSelectIncludesTag
    })
    .slice(0, numberOfPosts)

  const onSelect = selects => {
    setPopularTopicState(popularTopicState.map(t => ({ ...t, active: false })))
    setSelection(selects)
  }
  const toggleTopic = value => {
    setSelection([]) // clearing the topic select
    setPopularTopicState(
      popularTopicState.reduce((acc, next) => {
        if (next.value === value) {
          if (!next.active) {
            // if the popular topic will be active
          }
          return [...acc, { value, active: !next.active }]
        }
        return [...acc, next]
      }, [])
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Home" />
      {/* Popular Topics */}
      <h4>🔥 Popular Topics</h4>
      <div className="popular-topics">
        {popularTopicState.map(({ value, active }, i) => (
          <div
            className={active ? "popular-topic-active" : ""}
            onClick={() => toggleTopic(value)}
            key={i}
          >
            {value}
          </div>
        ))}
      </div>

      {filteredPosts.length ? (
        <div className="posts-container">
          {filteredPosts.map(({ node, rTime }, i) => {
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div key={i} className="post-thumbnail">
                <CoverImage src={node.frontmatter.cover_image} />
                <article key={node.fields.slug}>
                  <header>
                    <h4
                      style={{
                        marginBottom: rhythm(1 / 4),
                      }}
                    >
                      <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                        {title}
                      </Link>
                    </h4>
                    <span>{node.frontmatter.author} · </span>
                    <span>
                      {node.frontmatter.date} · {node.timeToRead} min read
                    </span>
                    <div>
                      {node.frontmatter.tags.map((t, i) => (
                        <Link key={i} to="/archive/" state={{ topic: t }}>
                          <FaTag size={14} color="#FFD679" />
                          {t}{" "}
                        </Link>
                      ))}
                    </div>
                  </header>
                  <section>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: node.frontmatter.description || node.excerpt,
                      }}
                    />
                  </section>
                </article>
              </div>
            )
          })}
        </div>
      ) : (
        <div>
          <h4>
            Sorry there is no post about this topic yet. Do you want to be the
            first?
          </h4>
          <Link to="/guest-blog/">Submit Guest Blog</Link>
        </div>
      )}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        popularTopics
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { course: { eq: null } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            cover_image {
              base
            }
            author
          }
          timeToRead
        }
      }
    }
  }
`
